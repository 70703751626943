<template>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <main class="flex items-center justify-center">
        <div style="width: 570px; height: 570px" class="w-1/2 my-14">
            <img
                src="@/assets/images/suburban-logo-blue.svg"
                alt="suburban logo"
                class="mx-auto "
            />
            <div class="w-full h-full rounded bg-white shadow my-10 py-16 px-24">
                <h1 class="font-bold text-2xl text-center leading-loose">
                    Procurement Management System
                </h1>
                <h3 class="text-md text-center">Enter your credentials below</h3>
                <div class="pt-12">
                    <label for="email" class="capitalize leading-loose">email</label>
                    <input
                        v-model="email"
                        type="text"
                        id="email"
                        placeholder="name@suburbanfiberco.com"
                        class="block w-full py-2 px-3.5 border borderColor rounded"
                    />
                </div>
                <div class="my-8 relative">
                    <label for="password" class="capitalize leading-loose">password</label>
                    <input
                        v-model="password"
                        :type="password_type"
                        id="password"
                        placeholder="*************"
                        class="block w-full py-2 px-3.5 border borderColor rounded"
                    />
                    <img
                        v-if="password"
                        @click="togglePasswordType"
                        src="@/assets/images/eye-icon.svg"
                        alt="eye icon"
                        class="w-6 absolute right-6 bottom-3 cursor-pointer text-blue-600"
                    />
                    <img
                        v-if="password_type === 'text'"
                        @click="togglePasswordType"
                        src="@/assets/images/eye-icon-blue.svg"
                        alt="eye icon"
                        class="w-6 absolute right-6 bottom-3 cursor-pointer text-blue-600"
                    />
                </div>
                <button
                    @click.prevent="login"
                    class="block w-full my-12 text-white py-2.5 rounded bg-primaryColor capitalize"
                >
                    login
                </button>
            </div>
        </div>
    </main>
</template>

<script>
import Request from '@/services/requestHelper'

export default {
    data() {
        return {
            email: null,
            password: null,
            password_type: 'password',
            errorMessage: null,
        }
    },
    methods: {
        togglePasswordType() {
            if (this.password_type === 'password') {
                this.password_type = 'text'
            } else {
                this.password_type = 'password'
            }
        },
        async login() {
            try {
                if (!this.email || !this.password) {
                    this.errorMessage = 'All fields are required.'

                    return
                }

                const { status, data } = await Request.postRequest('login', {
                    email: this.email,
                    password: this.password,
                })

                if (status && status === 200) {
                    this.$store.dispatch('updateUserState', data)
                    localStorage.setItem('lcl_auth_user_procurement', JSON.stringify(data))
                    this.$router.push({ name: 'Dashboard' })
                }
            } catch (error) {
                if (error.response) {
                    this.errorMessage = error.response.data.message
                }
            }
        },
    },
}
</script>
